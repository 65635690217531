exports.onRouteUpdate = ({ location, prevLocation }) => { 
    var referrer = document.referrer;

    //if landing URL does not exist in local storage, set current pathname
    if (typeof window !== 'undefined' && window.localStorage && !window.localStorage.getItem('landingpath')) {
        window.localStorage.setItem('landingpath', location.href)
        window.localStorage.setItem('landingdtm', new Date())
    }
 
    window.localStorage.setItem('currentpath', location.href)
    window.localStorage.setItem('prevpathname', prevLocation ? prevLocation.href : null) 
}