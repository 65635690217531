exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-localtier-js": () => import("./../../../src/pages/about-localtier.js" /* webpackChunkName: "component---src-pages-about-localtier-js" */),
  "component---src-pages-accessibility-statement-js": () => import("./../../../src/pages/accessibility-statement.js" /* webpackChunkName: "component---src-pages-accessibility-statement-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin/[...].js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-claim-slug-js": () => import("./../../../src/pages/claim/[...slug].js" /* webpackChunkName: "component---src-pages-claim-slug-js" */),
  "component---src-pages-claim-success-js": () => import("./../../../src/pages/claim/success.js" /* webpackChunkName: "component---src-pages-claim-success-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-get-blogged-js": () => import("./../../../src/pages/get-blogged.js" /* webpackChunkName: "component---src-pages-get-blogged-js" */),
  "component---src-pages-get-listed-js": () => import("./../../../src/pages/get-listed.js" /* webpackChunkName: "component---src-pages-get-listed-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-local-services-js": () => import("./../../../src/pages/local-services.js" /* webpackChunkName: "component---src-pages-local-services-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-awards-js": () => import("./../../../src/templates/awards.js" /* webpackChunkName: "component---src-templates-awards-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-listing-page-js": () => import("./../../../src/templates/listing-page.js" /* webpackChunkName: "component---src-templates-listing-page-js" */),
  "component---src-templates-parent-service-js": () => import("./../../../src/templates/parent-service.js" /* webpackChunkName: "component---src-templates-parent-service-js" */)
}

